<template>
  <app-page ref="page" :toolbar="toolbar" :list="list">
    <template #actions="{ row }">
      <el-button
        type="text"
        :disabled="row.status != 1"
        @click="onConfirming(row)"
      >
        确认
      </el-button>
    </template>
    <template #dialog>
      <app-result-dialog
        :visible.sync="confirm.visible"
        :loading="confirm.loading"
        v-bind="confirm"
        @confirm="onConfirm"
      />
    </template>
  </app-page>
</template>

<script>
import dayjs from 'dayjs';
import request from '@/utils/request';

export default {
  name: 'ListA',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['date'],
        model: {
          name: undefined,
          date: dayjs().format('YYYY-MM-DD'),
          status: undefined
        },
        fields: [
          { label: '名称', prop: 'name', placeholder: '请输入要搜索的名称' },
          {
            label: '预约日期',
            prop: 'date',
            placeholder: '请选择要搜索的预约日期',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '状态',
            prop: 'status',
            placeholder: '请输入要搜索的状态',
            type: 'select',
            options: [
              {
                label: '全部',
                value: undefined
              },
              {
                label: '未使用',
                value: 0
              },
              {
                label: '已使用',
                value: 1
              },
              {
                label: '已确认',
                value: 2
              },
              {
                label: '已取消',
                value: 3
              }
            ]
          }
        ]
      },
      list: {
        method: 'post',
        url: '/cosmetology/temp',
        fields: [
          { label: '姓名', prop: 'appointmentUserName', width: 120 },
          { label: '电话', prop: 'appointmentPhone', width: 160 },
          {
            label: '预约日期',
            prop: 'appointmentDate'
          },
          {
            label: '状态',
            prop: 'status',
            width: 100,
            align: 'right',
            colors: ['primary', 'warning', 'success', 'info'],
            formatter(row) {
              const values = ['未使用', '已使用', '已确认', '已取消'];
              return values[row.status];
            }
          }
        ]
      },
      confirm: {
        visible: false,
        loading: false,
        model: undefined
      }
    };
  },
  methods: {
    onConfirming(row) {
      this.confirm.model = row;
      this.confirm.visible = true;
    },
    async onConfirm(e) {
      const { $refs, confirm } = this;
      confirm.loading = true;
      await request.post('/cosmetology/temp/confirm', {
        id: e.id,
        operateUserId: sessionStorage.getItem('userId')
      });
      await $refs.page.getItems();
      confirm.visible = false;
      confirm.loading = false;
    }
  }
};
</script>
